<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Configurar Jobs</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Configurar
                  <span id="txtDashboardNomePlataforma">jobs</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                    <div
                      class="row col-sm-12 col-md-12 col-lg-12 pr-0"
                    >
                      <h4
                        class="aluno_font_color col-sm-4 col-md-4 col-lg-4"                                 
                      >
                        Lista de jobs ({{ fastAdminListaJobs.length }})
                      </h4>
                      <a 
                        :href="'/plataforma/' + parseInt($route.params.id_plataforma)" 
                        class="btn-novo btn-primary offset-sm-6 offset-md-6 offset-lg-6 col-sm-2 col-md-2 col-lg-2 mb-3 text-center btn-size"
                        @click.prevent="getFastAdminListaJobs($route.params.id_plataforma)"><small>Atualizar lista</small></a>                         
                    </div>

                    <div v-if="this.fastAdminJobsLoading" class="col-12 mt-2 mb-2">
                      <div class="row">
                        <div class="align-self-center mx-auto text-center">            
                          <h4>
                            <b-icon
                              icon="gear-fill"
                              animation="spin"
                            /> Carregando Jobs
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="fastAdminListaJobs.length" class="col-12 fd-app-jobs">
                      <div v-for="(job, index) in fastAdminListaJobs" :key="index">
                        <div class="card mb-4">
                          <div class="card-header text-center text-wrap">
                            <h4 class="p-2">{{ job.nome_job }}</h4>
                          </div>
                          <div class="card-body text-center pb-0">
                            <div>
                              <small>{{ job.descricao_job }}</small>
                            </div>
                            <div class="mt-2">
                              <p>
                                <span>Ativo</span>: 
                                <span v-if="job.ativo" class="text-success my-3">Sim</span>
                                <span v-else class="text-danger my-3">Não</span>
                              </p>
                            </div>
                            <div v-if="job.codigo_job === 'BLOQUEIA-ACESSO-PAGAMENTO-ALUNO'">
                              Dias de atraso para bloqueio do aluno: {{ fastParametrosPlataforma.fastDiasParaBloqueio }}
                            </div>
                            <div v-if="job.codigo_job === 'ENVIO-MENSAGEM-COBRANCA-ALUNO'">
                              Considerar inadimplências de no máximo {{ fastParametrosPlataforma.fastDiasParaEmail }} dias
                            </div>
                            <div v-if="job.data_ultima_execucao == null || job.data_ultima_execucao === '' ">
                              Última execução: Aguardando primeira verificação
                            </div>
                            <div v-else>
                              Última execução: {{ new Date(job.data_ultima_execucao).toLocaleString() }}
                            </div>
                            <div>
                              Frequência da execução:
                              <span v-if="job.frequencia_dia"> a cada {{ job.frequencia_dia }} dia(s) às {{ `${job.hora_execucao < 10 ? '0' + job.hora_execucao : job.hora_execucao}:${job.minuto_execucao == 0 ? '0' + job.minuto_execucao : job.minuto_execucao}` }}</span>
                              <span v-else-if="job.frequencia_hora">a cada {{ job.frequencia_hora }} hora(s)</span>
                              <span v-else-if="job.frequencia_minuto">a cada {{ job.frequencia_minuto }} minutos</span>
                            </div>
                            <div v-if="job.em_execucao">
                              Próxima execução: <span class="text-warning">Em execução</span> 
                            </div>
                            <div v-else-if="job.data_proxima_execucao == null || job.data_proxima_execucao === '' && !job.em_execucao">
                              Próxima execução: Aguardando primeira verificação
                            </div>
                            <div v-else>
                              Próxima execução: {{ new Date(job.data_proxima_execucao).toLocaleString() }}
                            </div>
                            <div class="my-3">
                              <a v-if="job.em_execucao"
                                :href="'/plataforma/' + parseInt($route.params.id_plataforma)" 
                                class="btn-novo btn-secondary mx-1"
                                @click.prevent="exibeModalJob($route.params.id_plataforma, job.id_job)">Editar Job</a>
                              <a v-else
                                :href="'/plataforma/' + parseInt($route.params.id_plataforma)" 
                                class="btn-novo btn-primary mx-1"
                                @click.prevent="exibeModalJob($route.params.id_plataforma, job.id_job)">Editar Job</a>
                              <a 
                                :href="'/plataforma/' + parseInt($route.params.id_plataforma)" 
                                class="btn-novo btn-info mx-1"
                                @click.prevent="exibeModalLogs(job.id_plataforma, job.id_job, job.id_job_plataforma)">Acessar Logs</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else class="col-12 mt-2 mb-2">
                      Não foram encontrados jobs para a plataforma
                    </div>

                  </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-10 mb-10">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->

    <modal name="modalJob" :shift-y="0.1" height="auto" :width="modalWidthMd" :scrollable="true"
      :adaptative="true">
      <div class="row fd-app-table-novo-logs m-pers-modal-jobs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h4>{{ this.fastAdminJob.nome_job }}</h4>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalJob')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <form class="text-center">
            <div class="my-2">
            </div>
            <div class="row d-flex justify-content-center align-items-center">
                  <label for="ativo" class="mx-1">Ativo:</label>
                  <input type="checkbox" id="ativo" v-model="fastAdminAtualizaJob.ativo" class="mb-1 checkbox-size form-control form-control-sm">
            </div>
            <div v-if="this.fastAdminAtualizaJob.ativo">
              <div v-if="this.fastAdminJob.codigo_job === 'BLOQUEIA-ACESSO-PAGAMENTO-ALUNO' " class="row d-flex justify-content-center align-items-center mb-3">
                    <label for="bloq_aluno">Bloquear o aluno após:</label>
                    <input type="number" id="bloq_aluno" class="form-control form-control-sm input-size mx-1 text-center" v-model="computedDiasParaBloq">
                    <label for="bloq_aluno">dias de inadimpência.</label>
              </div>
              <div v-if="this.fastAdminJob.codigo_job === 'ENVIO-MENSAGEM-COBRANCA-ALUNO'" class="row d-flex justify-content-center align-items-center mb-3">
                    <label for="msg_cobranca">Considerar inadimplências de no máximo</label>
                    <input type="number" id="msg_cobranca" class="form-control form-control-sm input-size mx-1 text-center" v-model="computedDiasParaEnvioEmail">
                    <label for="msg_cobranca">dias.</label>
              </div>
              <div v-if="this.fastAdminJob.nome_job">
                <div v-if="this.fastAdminJob.frequencia_dia_min" class="row d-flex justify-content-center align-items-center">
                  <label for="freq_tipo" class="mx-1">Tipo de frequência:</label>
                        <select name="freq_tipo" id="freq_tipo" class="select-size form-control form-control-sm mb-3" v-model="fastTipoFrequencia" @change="limpaCampos()">
                          <option value="fastAdminAtualizaJob.frequencia_dia">Dia</option>
                        </select>
                </div>
                <div v-else-if="this.fastAdminJob.frequencia_hora_min" class="row d-flex justify-content-center align-items-center">
                  <label for="freq_tipo" class="mx-1">Tipo de frequência:</label>
                        <select name="freq_tipo" id="freq_tipo" class="select-size form-control form-control-sm mb-3" v-model="fastTipoFrequencia" @change="limpaCampos()">
                          <option value="fastAdminAtualizaJob.frequencia_dia">Dia</option>
                          <option value="fastAdminAtualizaJob.frequencia_hora">Hora</option>
                        </select>
                </div>
                <div v-else-if="this.fastAdminJob.frequencia_minuto_min" class="row d-flex justify-content-center align-items-center">
                  <label for="freq_tipo" class="mx-1">Tipo de frequência:</label>
                        <select name="freq_tipo" id="freq_tipo" class="select-size form-control form-control-sm mb-3" v-model="fastTipoFrequencia" @change="limpaCampos()">
                          <option value="fastAdminAtualizaJob.frequencia_dia">Dia</option>
                          <option value="fastAdminAtualizaJob.frequencia_hora">Hora</option>
                          <option value="fastAdminAtualizaJob.frequencia_minuto">Minuto</option>
                        </select>
                </div>
              </div>
              <div v-if="fastTipoFrequencia === 'fastAdminAtualizaJob.frequencia_dia'" class="row d-flex justify-content-center align-items-center">
                    <label for="frequencia_dia">Frequência: Executar a cada</label>
                    <input type="number" id="frequencia_dia" class="input-size form-control form-control-sm  mx-1" 
                            v-model="fastAdminAtualizaJob.frequencia_dia">
                    <label for="frequencia_dia">dias.</label>
                    <label for="hora_execucao" class="mx-1">Executar às:</label>
                    <input type="time" id="hora_execucao" class="input-time-size form-control form-control-sm " v-model="fastAdminJobsTime.fastHoraSelecionada" @focusout="validaHorario()">
              </div>
              <div v-else-if="fastTipoFrequencia === 'fastAdminAtualizaJob.frequencia_hora'" class="row d-flex justify-content-center align-items-center mb-3">
                <label for="frequencia_hora">Frequência: Executar a cada</label>
                <input type="number" id="frequencia_hora" class="input-size form-control form-control-sm mx-1 text-center" 
                        v-model="fastAdminAtualizaJob.frequencia_hora">
                <label for="frequencia_hora">hora(s).</label>
              </div>
              <div v-else-if="fastTipoFrequencia === 'fastAdminAtualizaJob.frequencia_minuto'" class="row d-flex justify-content-center align-items-center mb-3">
                <label for="frequencia_min">Frequência: Executar a cada</label>
                <input type="number" id="frequencia_min" class="input-size form-control form-control-sm mx-1 text-center" 
                v-model="fastAdminAtualizaJob.frequencia_minuto">
                <label for="frequencia_min">minutos.</label>
              </div>
              <div v-if="fastTipoFrequencia != 'fastAdminAtualizaJob.frequencia_dia'">
                <div class="d-flex justify-content-center align-items-center">
                  <div>
                    <label for="proxima_execucao" class="mx-1">Próxima execução:</label>
                  </div>
                  <div>
                    <b-form-datepicker
                      class="input-date-size align-self-start"
                      size="sm"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data"
                      placeholder="Selecionar data"
                      label-help="Use o cursor para selecionar"
                      v-model="fastAdminJobsTime.fastDataProxExec"
                      @input="validaDataProxExec()"
                      ></b-form-datepicker>
                  </div>
                  <div>
                    <input type="time" id="proxima_execucao" class="input-time-size form-control form-control-sm" v-model="fastAdminJobsTime.fastHoraProxExec" @focusout="validaHorarioProxExec()">
                  </div>
                </div>
              </div>
            </div>
            <div class="my-3">
              <a class="btn-novo btn-primary text-white font-weight-light px-2"
                       id="btnSalvaAlteracoes" @click.prevent="atualizaJob()">Salvar alterações</a>
            </div>
          </form>
        </div>
      </div>
    </modal>


    <modal name="modalLogs" :shift-y="0.1" height="auto" :width="modalWidthLg" :scrollable="true"
      :adaptative="true">
      <div class="row fd-app-table-novo-logs m-pers-modal-jobs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Logs do job: {{ this.fastAdminJob.nome_job }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalLogs')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    class="col-12"
                  >                  
                    <div class="card mb-4">
                      <div class="card-header py-1">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="col-sm-12 col-md-12 col-lg-12 flex p-0">
                              <div class="d-flex align-items-center text-nowrap">
                                <div class="col-sm-3 col-md-3 col-lg-3 p-0">
                                  <label for="log_sucesso" class="text-secondary mb-0"><small>Status:</small></label>
                                  <select name="log_sucesso" id="log_sucesso" class="select-size form-control form-control-sm" v-model="fastAdminJobsLogs.sucesso">
                                    <option value="">Todos</option>
                                    <option value="true">Sucesso</option>
                                    <option value="false">Falha</option>
                                  </select>
                                </div>
                                <div class="col-sm-3 offset-sm-1 col-md-3 offset-md-1 col-lg-3 offset-lg-1 p-0">
                                  <label for="log_exec_acao" class="text-secondary mb-0"><small>Ação:</small></label>
                                  <select name="log_exec_acao" id="log_exec_acao" class="select-size form-control form-control-sm" v-model="fastAdminJobsLogs.realizou_acao">
                                    <option value="">Todos</option>
                                    <option value="true">Executou</option>
                                    <option value="false">Não executou</option>
                                  </select>
                                </div>
                              </div>
                              <b-row align-h="between">
                                <div class="text-left col-lg-9 col-md-9 col-sm-9">
                                  <label class="text-secondary mb-0 mx-0 row">
                                    <small class="pl-0 ml-0 pl-0 col-8">Filtrar por data:</small>
                                    <small
                                    class="col-4 text-right mr-0 pr-0"
                                    role="button"
                                    v-if="fastAdminJobsTime.fastDataLogIni || fastAdminJobsTime.fastDataLogFim" 
                                    @click="fastAdminJobsTime.fastDataLogIni = '', fastAdminJobsTime.fastDataLogFim = ''"
                                    >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                                  </label>
                                  <div class="d-flex">
                                    <b-form-datepicker 
                                    class="align-self-start"
                                    size="sm"
                                    locale="pt-BR"
                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                    label-no-date-selected="Selecionar data"
                                    placeholder="Selecionar data"
                                    label-help="Use o cursor para selecionar"
                                    v-model="fastAdminJobsTime.fastDataLogIni"
                                    ></b-form-datepicker>
                                    <small class="align-self-start pt-1 px-2">até</small>
                                    <b-form-datepicker 
                                    class="align-self-start"
                                    size="sm"
                                    locale="pt-BR"
                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                    label-no-date-selected="Selecionar data"
                                    placeholder="Selecionar data"
                                    label-help="Use o cursor para selecionar"
                                    v-model="fastAdminJobsTime.fastDataLogFim"
                                    ></b-form-datepicker>
                                  </div>              
                                </div>
                                <div class="col-lg-2 col-md-2 co2-sm-2 mt-3 ml-3 pt-1 text-nowrap">
                                  <a class="btn-novo btn-primary text-white font-weight-light px-2" @click.prevent="filtraLogs(fastAdminJobsTime.fastDataLogIni, fastAdminJobsTime.fastDataLogFim)">Pesquisar</a>
                                </div>
                                <div class="ml-auto mt-3 text-right d-flex justify-content-end align-items-center">
                                  <a
                                    class="text-success fast-cursor-refresh"
                                    @click.prevent="getLogsDoJob()"
                                  >
                                    <b-icon-arrow-counterclockwise font-scale="2" />
                                  </a>
                                </div>
                              </b-row>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-1">
                        <div v-if="this.fastLogsFiltro.length">
                          Total de resultados: {{ this.fastAdminPaginacao.fastTotalResults }}. Exibindo do {{ this.fastAdminPaginacao.fastPageInicio }} ao {{ this.fastAdminPaginacao.fastPageFim }}.
                        </div>
                        <div class="row">
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm text-nowrap">
                              <thead class="thead-dark">
                                <tr>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Data Início</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Data Fim</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Duração</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Status</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Detalhes</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="this.fastAdminJobsLogsLoading">
                                <tr>
                                  <td colspan="5">
                                    <div class="col-12 text-center">
                                      <div class="align-self-center mx-auto text-center">            
                                        <h4>
                                          <b-icon
                                            icon="gear-fill"
                                            animation="spin"
                                          /> Carregando
                                        </h4>
                                      </div>
                                    </div> 
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else-if="this.fastLogsFiltro.length">
                                <tr v-for="(log, index) in fastLogsFiltro" :key="index"
                                >
                                  <td class="align-middle text-center">
                                    {{ new Date(log.data_execucao.split(/[T.]/)[0]).toLocaleDateString("pt-BR", {timeZone: 'UTC'}) }} {{ log.data_execucao.split(/[T.]/)[1] }}
                                  </td>
                                  <td class="align-middle text-center">
                                    {{ new Date(log.data_fim.split(/[T.]/)[0]).toLocaleDateString("pt-BR", {timeZone: 'UTC'}) }} {{ log.data_fim.split(/[T.]/)[1] }}
                                  </td>
                                  <td class="align-middle text-center">
                                    {{ log.duracao.split(".")[0] }}
                                  </td>
                                  <td class="align-middle text-center">
                                    <small
                                      v-if="log.sucesso"
                                      class="btn btn-sm btn-success pt-0 pb-0 text-lowercase"
                                      >Sucesso</small>
                                      <small
                                      v-else
                                      class="btn btn-sm btn-danger pt-0 pb-0 text-lowercase"
                                      >Falha</small>
                                  </td>
                                  <td class="align-middle text-center">
                                    <a class="btn-novo btn-primary text-white font-weight-light px-2" 
                                    :href="'/plataforma/' + parseInt($route.params.id_plataforma)" 
                                      @click.prevent="exibeModalDetalhesLog(log.id_job_plataforma_log)">
                                      Mais detalhes ({{ log.qtd_detalhes }})
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Não foram encontrados logs para a opção selecionada.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="align-self-center mx-auto text-center">
                            <b-pagination class="mt-1"
                              v-model="fastAdminPaginacao.fastPageIndex"
                              :total-rows="this.fastAdminPaginacao.fastTotalResults"
                              :per-page="fastAdminPaginacao.fastPageSize"
                              @change="pagLogs"

                              ></b-pagination>
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </transition>
              </div>
        </div>
      </div>
    </modal>
    <modal name="modalDetalhesLog" :shift-y="0.1" height="auto" :width="modalWidthLg" :scrollable="true"
      :adaptative="true">
      <div class="row fd-app-table-novo-logs m-pers-modal-jobs">
        <head class="col-12 d-flex justify-content-between">
          <div class="pl-3 pt-2">
            <h5>Logs do job: {{ this.fastAdminJob.nome_job }}</h5>
          </div>
          <div class="pr-3 pt-2">
            <a class="btn-novo btn-light" href="#" @click.prevent="hideModal('modalDetalhesLog')">
              <b-icon-arrow-return-left class="text-orange"/>
            </a>
          </div>
        </head>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div>
            <div class="col-sm-12 col-md-12 col-lg-12 my-2">
              Total de resultados: {{ this.fastAdminPaginacao.fastPageDetalhesTotalResults }}. Exibindo do {{ this.fastAdminPaginacao.fastPageDetalhesInicio }} ao {{ this.fastAdminPaginacao.fastPageDetalhesFim }}.
            </div>
            <transition-group name="slide-fade">
              <div v-if="fastAdminJobsLogsLoading">
                <div class="row">
                  <div class="align-self-center mx-auto text-center">            
                    <h4>
                      <b-icon
                        icon="gear-fill"
                        animation="spin"
                      /> Carregando Jobs
                    </h4>
                  </div>
                </div>
              </div>
              <div
                class="col-12"
                v-else
                v-for="(log) in this.fastDetalhesLogs" :key="log.id_job_plataforma_log_detalhe"
              >                  
                <div class="card shadow p-2">
                  {{ log.response }}
                </div>                  
              </div>
            </transition-group>
          </div>
          <div class="row">
            <div class="mx-auto align-self-center text-center pt-2">
              <b-pagination class="mt-1"
                v-model="fastAdminPaginacao.fastPageDetalhesIndex"
                :total-rows="fastAdminPaginacao.fastPageDetalhesTotalResults"
                :per-page="fastAdminPaginacao.fastPageDetalhesSize"
                @change="pagLogsDetalhes"
                ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoAdminPersonalizarPlataforma",
  components: {
    VueCropper,
    Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      fastPlataforma: {
        id_plataforma: 0,
        nome_plataforma: "",
        alteracao_pendente: false,
      },
      fastAdminJobsLoading: true,
      fastAdminJobsLogsLoading: true,
      fastAdminJobsLogsDetalhesLoading: true,
      fastAdminListaJobs: [],
      fastAdminJob: {
        id_job_plataforma: 0,
        id_job: 0,
        id_plataforma: 0,
        codigo_job: "",
        nome_job: "",
        descricao_job: "",
        em_execucao: false,
        frequencia_dia: null,
        frequencia_hora: null,
        frequencia_minuto: null,
        hora_execucao: null,
        minuto_execucao: null,
        data_ultima_execucao: "",
        data_proxima_execucao: "",
        frequencia_dia_min: null,
        frequencia_hora_min: null,
        frequencia_minuto_min: null,
        ativo: true
      },
      fastAdminAtualizaJob: {
        id_job_plataforma: null,
        id_job: null,
        id_plataforma: null,
        nome_job: null,
        descricao_job: null,
        em_execucao: false,
        frequencia_dia: null,
        frequencia_hora: null,
        frequencia_minuto: null,
        hora_execucao: null,
        minuto_execucao: null,
        data_ultima_execucao: null,
        data_proxima_execucao: null,
        frequencia_dia_min: null,
        frequencia_hora_min: null,
        frequencia_minuto_min: null,
        ativo: true
      },
      fastAdminJobsLogs: {
        id_job_plataforma_log: null,
        id_job_plataforma: null,
        data_execucao: "",
        sucesso: "",
        data_fim: "",
        duracao: "",
        realizou_acao: "",
        qtd_detalhes: null
      },
      fastTipoFrequencia:"",
      fastParametrosPlataforma: {
        fastDiasParaBloqueio: null,
        fastDiasParaEmail: null,
      },
      fastAdminJobsTime: {
        fastHoraSelecionada: "",
        fastDataProxExec: "",
        fastHoraProxExec: "",
        fastDataHoraProxExec: "",
        fastDataAtual: new Date(),
        fastDataLogIni: "",
        fastDataLogFim: "",
      },
      fastAdminListaLogs: {},
      fastLogsFiltro: {},
      fastDetalhesLogs: [],
      fastAdminPaginacao: {
        fastPageIndex: 1,
        fastTotalResults: "",
        fastPageSize: 10,
        fastPageInicio: "",
        fastPageFim: "",    
        fastPageDetalhesIndex: 1,
        fastPageDetalhesTotalResults: "",
        fastPageDetalhesSize: 10,
        fastPageDetalhesInicio: "",
        fastPageDetalhesFim: "",
        fastLogDetalheIdJobPlataformaLog: "",
      },
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma){
      this.getFastAdminListaJobs(this.$route.params.id_plataforma)
      .then(() => {
        // console.log("this.$route.params.id_plataforma " + this.$route.params.id_plataforma)
        this.$store.state.fastCarregando = false;
      })
      .catch((e) => {
        this.exibeToasty(e, "error");
        this.$store.state.fastCarregando = false;
      })
      this.getParametrosPlataforma(this.$route.params.id_plataforma)
      .then(() => {
        // console.log("DIAS PARA BLOQ.$route.params.id_plataforma " + this.$route.params.id_plataforma)
        this.$store.state.fastCarregando = false;
      })
    }
  },
  computed:{
    computedDiasParaBloq: {
      get() {
      if (this.fastParametrosPlataforma.fastDiasParaBloqueio != null){
        return this.fastParametrosPlataforma.fastDiasParaBloqueio.toString();
      }
      },
      set(val) {
        this.fastParametrosPlataforma.fastDiasParaBloqueio = Number(val);
      }
    },
    computedDiasParaEnvioEmail: {
      get() {
      if (this.fastParametrosPlataforma.fastDiasParaEmail != null){
        return this.fastParametrosPlataforma.fastDiasParaEmail.toString();
      }
      },
      set(val) {
        this.fastParametrosPlataforma.fastDiasParaEmail = Number(val);
      }
    },
    computedHoraDeExecucao: {
      get () {
        if (this.fastAdminJobsTime.fastHoraSelecionada != null) {
          return this.fastAdminJobsTime.fastHoraSelecionada.split(':')[0];
        }
      },
      set (novaHora) {
          this.fastAdminJobsTime.fastHoraSelecionada = `${novaHora}:${this.computedMinutoDeExecucao}`
      }
    },
    computedMinutoDeExecucao: {
      get () {
        if (this.fastAdminJobsTime.fastHoraSelecionada != null) {
          return this.fastAdminJobsTime.fastHoraSelecionada.split(':')[1];
        }
      },
      set (novoMinuto) {
        this.fastAdminJobsTime.fastHoraSelecionada = `${this.computedHoraDeExecucao}:${novoMinuto}`
      }
    },
    computedHoraProxExecucao: {
      get () {
        if (this.fastAdminJobsTime.fastHoraProxExec != null) {
          return this.fastAdminJobsTime.fastHoraProxExec.split(':')[0];
        }
      },
      set (novaHora) {
        this.fastAdminJobsTime.fastHoraProxExec = `${novaHora}:${this.computedMinutoProxExecucao}`
      }
    },
    computedMinutoProxExecucao: {
      get () {
        return this.fastAdminJobsTime.fastHoraProxExec.split(':')[1];
      },
      set (novoMinuto) {
        this.fastAdminJobsTime.fastHoraProxExec = `${this.computedHoraProxExecucao}:${novoMinuto}`
      }
    },
  },
  methods: {
    async getFastAdminListaJobs(id_plataforma) {
      this.fastAdminJobsLoading = true;
      this.fastAdminListaJobs = [];
      this.promiseGetFastApi("api/fast_jobs_plataforma/lista", "id_plataforma=" + id_plataforma)
      .then(obj => {
        const listaJobs = [];
        if (obj.length){
          obj.forEach(
            j => {
              listaJobs.push(j);
            })
        }
        // console.log(listaJobs);
        this.fastAdminListaJobs = listaJobs;
        this.fastAdminJobsLoading = false;
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLoading = false;
      })
    },
    async getParametrosPlataforma (id_plataforma) {
      this.fastParametrosPlataforma.fastDiasParaBloqueio = null;
      this.promiseGetFastApi("api/fast_plataforma_parametro/lista", "id_plataforma=" + id_plataforma)
      .then(obj => {
        if (obj) {
          this.fastParametrosPlataforma.fastDiasParaBloqueio = obj[0].dias_bloqueio_inadimplencia
          this.fastParametrosPlataforma.fastDiasParaEmail = obj[0].dias_envio_email_inadimplencia
        }
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLoading = false;
      })
    },
    limpaCampos() {
      this.fastAdminAtualizaJob.frequencia_minuto = null
      this.fastAdminAtualizaJob.frequencia_hora = null
      this.fastAdminAtualizaJob.frequencia_dia = null
      this.fastAdminAtualizaJob.hora_execucao = null
      this.fastAdminAtualizaJob.minuto_execucao = null
      this.fastAdminJobsTime.fastHoraSelecionada = null
      this.fastAdminJobsTime.fastDataProxExec = null
      this.fastAdminJobsTime.fastHoraProxExec = null
      // console.log("limpacampos "+this.fastAdminAtualizaJob)
    },
    exibeModalJob(id_plataforma, id_job) {
      this.fastAdminJobsLoading = true;
      this.fastAdminJob = {};
      this.promiseGetFastApi("api/fast_jobs_plataforma/lista", "id_plataforma=" + id_plataforma + "&id_job=" + id_job)
      .then(obj => {
        if (obj){
          this.fastAdminJob = obj[0];
        }
        this.fastAdminAtualizaJob = obj[0];
        if (this.fastAdminAtualizaJob.frequencia_dia > 0) {
          this.fastTipoFrequencia = 'fastAdminAtualizaJob.frequencia_dia'
        } else if (this.fastAdminAtualizaJob.frequencia_hora > 0) {
          this.fastTipoFrequencia = 'fastAdminAtualizaJob.frequencia_hora'
        } else if (this.fastAdminAtualizaJob.frequencia_minuto > 0) {
          this.fastTipoFrequencia = 'fastAdminAtualizaJob.frequencia_minuto'
        }
        if (this.fastAdminAtualizaJob.data_proxima_execucao == null) {
          this.fastAdminJobsTime.fastHoraProxExec = ''
        }
        if (this.fastAdminAtualizaJob.data_proxima_execucao != null) {
          if (this.fastAdminAtualizaJob.frequencia_minuto > 0 || this.fastAdminAtualizaJob.frequencia_hora > 0) {
            this.fastAdminJobsTime.fastDataProxExec = this.fastAdminAtualizaJob.data_proxima_execucao.split(/[T.]/)[0]
            this.fastAdminJobsTime.fastHoraProxExec = this.fastAdminAtualizaJob.data_proxima_execucao.split(/[T.]/)[1]
          }
        } 
        if (this.fastAdminAtualizaJob.hora_execucao == 0) {
          this.fastAdminAtualizaJob.hora_execucao = '00'
        } else if (this.fastAdminAtualizaJob.hora_execucao > 0 && this.fastAdminAtualizaJob.hora_execucao < 10){
          this.fastAdminAtualizaJob.hora_execucao = '0' + this.fastAdminAtualizaJob.hora_execucao
        }
        if (this.fastAdminAtualizaJob.minuto_execucao == 0) {
          this.fastAdminAtualizaJob.minuto_execucao = '00'
        }
        this.fastAdminJobsTime.fastHoraSelecionada = `${this.fastAdminAtualizaJob.hora_execucao}:${this.fastAdminAtualizaJob.minuto_execucao}`
        // if (this.fastAdminAtualizaJob.frequencia_dia > 0) {
        //   console.log(this.fastAdminJobsTime.fastHoraSelecionada)
        // }
        // console.log(this.fastAdminJobsTime)  
        // console.log(this.fastAdminAtualizaJob)
        this.fastAdminJobsLoading = false;
        if (!this.fastAdminJob.em_execucao){
          this.showModal('modalJob')
        } else {
          this.exibeToasty("Job em execução, não é possível editar nesse momento", "info")
        }
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLoading = false;
      })
    },
    exibeModalLogs(id_plataforma, id_job, id_job_plataforma) {
      this.fastAdminJobsLogsLoading = true;
      this.fastAdminListaLogs = {};
      this.fastAdminJob = {};
      this.fastAdminJobsTime.fastDataLogIni = ""
      this.fastAdminJobsTime.fastDataLogFim = ""
      this.fastAdminJobsLogs.sucesso = ""
      this.fastAdminJobsLogs.realizou_acao = ""
      this.promiseGetFastApi("api/fast_jobs_plataforma/lista", "id_plataforma=" + id_plataforma + "&id_job=" + id_job)
      .then(obj => {
        if (obj){
          this.fastAdminJob = obj[0];
        }
        this.fastAdminAtualizaJob = obj[0];
        this.fastAdminJobsLogsLoading = false;
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLogsLoading = false;
      })
      id_job_plataforma = id_job_plataforma == null ? id_job_plataforma = '0' : id_job_plataforma
      this.promiseGetFastApi("api/fast_jobs_plataforma/lista_logs", "id_job_plataforma=" + id_job_plataforma + "&page_size=" + this.fastAdminPaginacao.fastPageSize)
      .then(obj => {
        let listaLogs = {};
        if (obj){
          listaLogs = obj.data
          this.fastAdminPaginacao.fastTotalResults = obj.total_results
          this.fastAdminPaginacao.fastPageInicio = obj.range_start
          this.fastAdminPaginacao.fastPageFim = obj.range_end
        }
        this.fastAdminListaLogs = listaLogs;
        this.fastLogsFiltro = listaLogs;
        if (this.fastLogsFiltro == null) {
          this.fastLogsFiltro = 0
        }
        this.fastAdminJobsLogsLoading = false;
        this.showModal('modalLogs')
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLogsLoading = false;
      })
    },
    exibeModalDetalhesLog(id_detalhe){
      this.fastAdminJobsLogsDetalhesLoading = true;
      this.fastAdminPaginacao.fastLogDetalheIdJobPlataformaLog = id_detalhe
      this.fastDetalhesLogs = []
      this.promiseGetFastApi("api/fast_jobs_plataforma/lista_log_detalhes", "id_job_plataforma_log=" + id_detalhe + "&page_number=" + 1 + "&page_size=" + this.fastAdminPaginacao.fastPageDetalhesSize)
      .then(obj => {
        if (obj){
          this.fastDetalhesLogs = obj.data;
          this.fastAdminPaginacao.fastPageDetalhesTotalResults = obj.total_results
          this.fastAdminPaginacao.fastPageDetalhesInicio = obj.range_start
          this.fastAdminPaginacao.fastPageDetalhesFim = obj.range_end
        }
        this.showModal('modalDetalhesLog')
        this.fastAdminJobsLogsDetalhesLoading = false;
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLogsDetalhesLoading = false;
      })
    },
    async pagLogsDetalhes(){
      this.fastAdminJobsLogsLoading = true
      this.$nextTick(() => {
        // console.log("pageindex " + this.fastAdminPaginacao.fastPageIndex)
        this.promiseGetFastApi("api/fast_jobs_plataforma/lista_log_detalhes", "id_job_plataforma_log=" + this.fastAdminPaginacao.fastLogDetalheIdJobPlataformaLog + "&page_number=" + this.fastAdminPaginacao.fastPageDetalhesIndex + "&page_size=" + this.fastAdminPaginacao.fastPageDetalhesSize)
        .then(obj => {
          if (obj){
            this.fastDetalhesLogs = obj.data;
            this.fastAdminPaginacao.fastPageDetalhesTotalResults = obj.total_results
            this.fastAdminPaginacao.fastPageDetalhesInicio = obj.range_start
            this.fastAdminPaginacao.fastPageDetalhesFim = obj.range_end
          }
          this.fastAdminJobsLogsLoading = false;
        }).catch(e => {
          console.log(e);
          this.fastAdminJobsLogsLoading = false;
        })
      })
    },
    getLogsDoJob(){
      this.fastAdminJobsLogsLoading = true;
      this.fastAdminListaLogs = {};
      this.fastAdminJobsTime.fastDataLogIni = '';
      this.fastAdminJobsTime.fastDataLogFim = '';
      this.fastAdminJobsLogs.sucesso = ""
      this.fastAdminJobsLogs.realizou_acao = ""
      this.fastAdminPaginacao.fastPageIndex = 1
      this.promiseGetFastApi("api/fast_jobs_plataforma/lista_logs", "id_job_plataforma=" + this.fastAdminJob.id_job_plataforma + "&page_size=" + this.fastAdminPaginacao.fastPageSize)
      .then(obj => {
        let listaLogs = {};
        if (obj){
          listaLogs = obj.data
          this.fastAdminPaginacao.fastTotalResults = obj.total_results
          this.fastAdminPaginacao.fastPageInicio = obj.range_start
          this.fastAdminPaginacao.fastPageFim = obj.range_end
        }
        this.fastLogsFiltro = listaLogs;
        this.fastAdminJobsLogsLoading = false;
      }).catch(e => {
        console.log(e);
        this.fastAdminJobsLogsLoading = false;
      })
    },
    filtraLogs(dataIni, dataFim){
      this.fastAdminJobsLogsLoading = true;
      if (dataIni != "" && dataFim != "") {
        dataIni += "T00:01"
        dataFim += "T23:59"
      }
      if (dataIni && !dataFim || !dataIni && dataFim) {
          this.exibeToasty("Por favor, preencha ambas as datas", "error")
      } else if (dataIni > dataFim ) {
          this.exibeToasty("A data final deve ser maior ou igual a data inicial", "error")
      } else {
            // console.log(this.fastAdminJob.id_job_plataforma)
            this.fastAdminPaginacao.fastPageIndex = 1
            this.promiseGetFastApi("api/fast_jobs_plataforma/lista_logs", "id_job_plataforma=" + this.fastAdminJob.id_job_plataforma + "&data_inicio=" + dataIni + "&data_fim=" + dataFim + "&sucesso=" + this.fastAdminJobsLogs.sucesso + "&realizou_acao=" + this.fastAdminJobsLogs.realizou_acao + "&page_number=1&page_size=" + this.fastAdminPaginacao.fastPageSize)
            .then(obj => {
            let listaLogs = {};
            if (obj){
              listaLogs = obj.data
              this.fastAdminPaginacao.fastTotalResults = obj.total_results
              this.fastAdminPaginacao.fastPageInicio = obj.range_start
              this.fastAdminPaginacao.fastPageFim = obj.range_end
            }
            this.fastLogsFiltro = listaLogs;
            this.fastAdminJobsLogsLoading = false;
          }).catch(e => {
            console.log(e);
            this.fastAdminJobsLogsLoading = false;
          })
      }
    },
    async pagLogs(){
      this.fastAdminJobsLogsLoading = true
      // console.log(this.fastAdminJob.id_job_plataforma)
      let dataIni = this.fastAdminJobsTime.fastDataLogIni
      let dataFim = this.fastAdminJobsTime.fastDataLogFim
      if (dataIni != "" && dataFim != "") {
        dataIni += "T00:01"
        dataFim += "T23:59"
      }
      this.$nextTick(() => {
        // console.log("pageindex " + this.fastAdminPaginacao.fastPageIndex)
        this.promiseGetFastApi("api/fast_jobs_plataforma/lista_logs", "id_job_plataforma=" + this.fastAdminJob.id_job_plataforma + "&data_inicio=" + dataIni + "&data_fim=" + dataFim + "&page_number=" + this.fastAdminPaginacao.fastPageIndex + "&page_size=" + this.fastAdminPaginacao.fastPageSize)
        .then(obj => {
          let listaLogs = {};
          if (obj){
            listaLogs = obj.data
            this.fastAdminPaginacao.fastTotalResults = obj.total_results
            this.fastAdminPaginacao.fastPageInicio = obj.range_start
            this.fastAdminPaginacao.fastPageFim = obj.range_end
          }
          this.fastLogsFiltro = listaLogs;
          this.fastAdminJobsLogsLoading = false;
        }).catch(e => {
          console.log(e);
          this.fastAdminJobsLogsLoading = false;
        })
      })
    },
    async atualizaJob() {
      this.$store.state.fastCarregando = true
      // console.log(this.fastAdminJobsTime.fastDataProxExec)
      if (this.fastAdminJobsTime.fastDataProxExec && this.fastAdminJobsTime.fastHoraProxExec){
        const combinedISODateTime = `${this.fastAdminJobsTime.fastDataProxExec}T${this.fastAdminJobsTime.fastHoraProxExec}`;
        this.fastAdminJobsTime.fastDataHoraProxExec = combinedISODateTime
      }
      let dadosJob = {
        id_job: this.fastAdminAtualizaJob.id_job,
        id_plataforma: this.fastAdminAtualizaJob.id_plataforma,
        ativo: this.fastAdminAtualizaJob.ativo,
        frequencia_dia: this.fastAdminAtualizaJob.frequencia_dia,
        frequencia_hora: this.fastAdminAtualizaJob.frequencia_hora,
        frequencia_minuto: this.fastAdminAtualizaJob.frequencia_minuto,
        frequencia_dia_min: this.fastAdminAtualizaJob.frequencia_dia_min,
        frequencia_hora_min: this.fastAdminAtualizaJob.frequencia_hora_min,
        frequencia_minuto_min: this.fastAdminAtualizaJob.frequencia_minuto_min,
        hora_execucao: this.computedHoraDeExecucao == "null" ? this.computedHoraDeExecucao = null : this.computedHoraDeExecucao,
        minuto_execucao: this.computedMinutoDeExecucao == "null" ? this.computedMinutoDeExecucao = null : this.computedMinutoDeExecucao,
        data_proxima_execucao: this.fastAdminJobsTime.fastDataHoraProxExec ? this.fastAdminJobsTime.fastDataHoraProxExec : null,
      }
      
      if (dadosJob.frequencia_hora_min) {
        if (dadosJob.frequencia_hora && dadosJob.frequencia_hora < dadosJob.frequencia_hora_min){
          this.exibeToasty(`Este job não permite frequência menor do que ${dadosJob.frequencia_hora_min} horas`, "error")
        }
      }
      if (this.fastTipoFrequencia === 'fastAdminAtualizaJob.frequencia_dia') {
        if (dadosJob.hora_execucao == '' || dadosJob.hora_execucao == null || dadosJob.minuto_execucao == '' || dadosJob.minuto_execucao == null) {
          this.exibeToasty("Para uma frequência diária deve ser selecionada a hora de execução", "error")
        }
        if (dadosJob.frequencia_dia < dadosJob.frequencia_dia_min) {
          this.exibeToasty(`A frequência por dia não pode ser menor do que ${dadosJob.frequencia_dia_min}`, "error")
        }
      }
      if (dadosJob.frequencia_minuto_min) {
        if (dadosJob.frequencia_minuto && dadosJob.frequencia_minuto < dadosJob.frequencia_minuto_min){
          this.exibeToasty(`Este job não permite frequência menor do que ${dadosJob.frequencia_minuto_min} minutos`, "error")
        }
        const numerosPermitidos = ['20', '30', '40', '50']
        // console.log("freqmin "+dadosJob.frequencia_minuto)
        if (dadosJob.frequencia_minuto && !numerosPermitidos.includes(String(dadosJob.frequencia_minuto))){
          this.exibeToasty("Por favor, a frequência por minuto deve ser de 20, 30, 40 ou 50 minutos.", "error")
        }
      }
      if (dadosJob.frequencia_dia > 0) {
        dadosJob.data_proxima_execucao = null
      }
      // console.log("dadosjob" + JSON.stringify(dadosJob))
      try {
        let resp = await fetch(
          settings.endApiFastEad + ("api/fast_jobs_plataforma/atualiza"),
          this.fastAjaxOptions("POST", JSON.stringify(dadosJob))
        )
        .then((resp) => resp.json())
        .then(response => {
          if(response.success){
            this.exibeToasty("Job alterado com sucesso!")
            this.hideModal('modalJob')
          }
        })
        this.$store.state.fastCarregando = false
      } catch (e){
        console.log("Erro", e);
        this.$store.state.fastCarregando = false 
      }
      if (this.fastParametrosPlataforma.fastDiasParaBloqueio != null || this.fastParametrosPlataforma.fastDiasParaBloqueio >= 1){
      let obj = { 
        id_plataforma: this.$route.params.id_plataforma,  
        dias_bloqueio_inadimplencia: this.fastParametrosPlataforma.fastDiasParaBloqueio,
        dias_envio_email_inadimplencia: this.fastParametrosPlataforma.fastDiasParaEmail
      }
      try {
        let resp = await fetch(
          settings.endApiFastEad + ("api/fast_plataforma_parametro/atualiza"),
          this.fastAjaxOptions("POST", JSON.stringify(obj))
        );
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false        
      }}
      this.getFastAdminListaJobs (dadosJob.id_plataforma)
    },
    validaHorario () {
      const numerosPermitidos = ['00', '10', '20', '30', '40', '50']
      if (!numerosPermitidos.includes(this.computedMinutoDeExecucao)){
        this.exibeToasty("Por favor, escolha um horário com 00 minuto ou minutos múltiplos de 10", "error")
        this.computedMinutoDeExecucao = '00'
      }
    },
    validaHorarioProxExec(){
      const ano = this.fastAdminJobsTime.fastDataAtual.getFullYear();
      const mes = (this.fastAdminJobsTime.fastDataAtual.getMonth() + 1).toString().padStart(2, '0');
      const dia = this.fastAdminJobsTime.fastDataAtual.getDate().toString().padStart(2, '0');
      let dataFormatada = `${ano}-${mes}-${dia}`
      const hora = this.fastAdminJobsTime.fastDataAtual.getHours().toString().padStart(2, '0');
      const minuto = this.fastAdminJobsTime.fastDataAtual.getMinutes().toString().padStart(2, '0');
      let horarioFormatado = `${hora}:${minuto}`
      if(this.fastAdminJobsTime.fastDataProxExec == dataFormatada){
        if(this.fastAdminJobsTime.fastHoraProxExec < horarioFormatado){
          this.fastAdminJobsTime.fastHoraProxExec = ''
          this.exibeToasty("Por favor, escolha um horário posterior ao atual", "error")
        }
      }

      const numerosPermitidos = ['00', '10', '20', '30', '40', '50']
      if (!numerosPermitidos.includes(this.computedMinutoProxExecucao)){
        this.exibeToasty("Por favor, escolha um horário com 00 minuto ou minutos múltiplos de 10", "error")
        this.computedMinutoProxExecucao = '00'
      }
    },
    validaDataProxExec () {
      const ano = this.fastAdminJobsTime.fastDataAtual.getFullYear();
      const mes = (this.fastAdminJobsTime.fastDataAtual.getMonth() + 1).toString().padStart(2, '0');
      const dia = this.fastAdminJobsTime.fastDataAtual.getDate().toString().padStart(2, '0');
      let dataFormatada = `${ano}-${mes}-${dia}`
      console.log(dataFormatada)
      if(this.fastAdminJobsTime.fastDataProxExec < dataFormatada){
        this.fastAdminJobsTime.fastDataProxExec = dataFormatada
        // console.log("data"+this.fastAdminJobsTime.fastDataProxExec)
        this.exibeToasty("Por favor, escolha uma data igual a hoje ou posterior", "error")
      }
    }
  },
};
</script>

<style scope>

.input-size{
  width: 75px;
}
.input-time-size {
  width: 100px;
}

.input-date-size {
  width: 175px;
}

.checkbox-size {
  height: 15px;
  width: 15px;
}

.select-size {
  width: 200px;
}

.btn-size {
  height: 30px;
}

.vm--modal {
  overflow: visible;
  border-radius: 8px 8px 0 0;
  border: 1px solid #f18938;
}

.m-pers-modal-jobs{
  margin-left: 0.0005rem !important;
  margin-right: 0.0005rem !important; 
}

</style>
